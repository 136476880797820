import { FelaCSS } from '../../../components/fela/flowtypes';
import { transition } from '../../../themes/variables';

interface StyleTypes {
  wrapper: ({ show, height }: { show?: boolean; height: number }) => FelaCSS;
}

const styles = (): StyleTypes => ({
  wrapper: ({ show, height }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    height: show ? `${height}px` : '0',
    transition: `height ${transition.fast} ease-in-out`,
    overflow: 'hidden',
  }),
});

export default styles;
