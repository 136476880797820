import React, { ChangeEvent, ReactNode } from 'react';
import { Box, TSetRef } from '@bridebook/ui';
import { extractStylingProps, stripStylingProps } from '@bridebook/ui/src/utils/styling-props';
import Text from '../../../../components/fela/Text';
import IconTickBold from '../../../../components/svg/icon-tick-bold';
import { IColorTypes, IStylingProps } from '../../../../themes/types';
import componentStyles from './checkbox-shell.style';

interface IProps extends IStylingProps {
  id?: string;
  name?: string;
  disabled?: boolean;
  setRef?: TSetRef;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  value?: boolean | string;
  checked?: boolean;
  radio?: boolean;
  rounded?: boolean;
  error?: boolean;
  label?: string | ReactNode;
  doNotStyleCheckedLabel?: boolean;
  alignCheckboxToTop?: boolean;
  variant?: 'primary' | 'teal';
  isUK?: boolean;
  smallFont?: boolean;
}

const VARIANT_CHECK_COLOR = {
  primary: 'white',
  teal: 'black',
};

const CheckboxShell = ({
  id,
  disabled,
  name,
  onChange,
  checked,
  setRef,
  rounded,
  label,
  radio,
  variant = 'primary',
  isUK,
  smallFont,
  alignCheckboxToTop,
  error,
  ...props
}: IProps) => {
  const styles = componentStyles({
    ...props,
    alignCheckboxToTop,
    disabled,
    label: Boolean(label),
    checked,
    rounded,
    variant,
    isUK,
    smallFont,
  });

  return (
    <Box style={styles.wrapper} data-name="checkbox-shell" {...extractStylingProps(props)}>
      <Text
        {...stripStylingProps(props)}
        as="input"
        type={radio ? 'radio' : 'checkbox'}
        id={id}
        disabled={disabled}
        name={name}
        onChange={onChange}
        checked={checked}
        ref={setRef}
        style={styles.checkboxInput}
      />
      <Box style={styles.checkbox}>
        <Box style={styles.iconWrap}>
          {rounded ? (
            <Box style={styles.circle} />
          ) : (
            <IconTickBold color={VARIANT_CHECK_COLOR[variant] as keyof IColorTypes} />
          )}
        </Box>
      </Box>
      <Box htmlFor={id} as="label" style={styles.label}>
        {label}
      </Box>
    </Box>
  );
};

export default CheckboxShell;
