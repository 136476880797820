import { MessageTheme } from 'src/components/bbcommon/persistent-message/types';
import { FelaCSS } from '../../../components/fela/flowtypes';
import { colors } from '../../../themes/variables';

interface IStyleProps {
  theme: MessageTheme;
}

interface StyleTypes {
  container: FelaCSS;
  headingWrap: FelaCSS;
  heading: FelaCSS;
  text: FelaCSS;
  buttonWrapper: FelaCSS;
  closeWrapper: FelaCSS;
}

const styles = ({ theme }: IStyleProps): StyleTypes => {
  const getTextColor = () => {
    if (theme === 'mega-alert') return colors.white;
    return colors.space;
  };

  return {
    container: {
      flexGrow: 1,
      position: 'static',
      flexDirection: 'row',
      alignItems: 'center',
      // paddingTop: '3px',
      // paddingBottom: '3px',

      maxWidthSm: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        paddingTop: '0',
        paddingBottom: '0',
      },
    },

    heading: {
      fontDefaultSemiBold: 16,
      lineHeight: '1.25em',
      color: getTextColor(),

      maxWidthSm: {
        paddingRight: '30px',
      },
    },

    headingWrap: {
      flexGrow: 1,
    },

    text: {
      marginTop: '8px',
      fontDefault: 16,
      lineHeight: '20px',
      color: getTextColor(),
    },

    buttonWrapper: {
      flexShrink: 0,
      flexWrap: 'wrap',
      flexDirection: 'row',
      paddingLeft: '40px',

      maxWidthSm: {
        paddingLeft: '0',
        marginTop: '16px',
      },
    },

    closeWrapper: {
      paddingLeft: '40px',

      maxWidthSm: {
        position: 'absolute',
        paddingLeft: '0',
        right: '0',
        top: '50%',
        transform: 'translateY(-50%)',
      },
    },
  };
};

export default styles;
