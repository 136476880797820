import React, { Component } from 'react';
import ReactHeight from 'react-height';
import Box from '../../../components/fela/Box';
import { IStylingProps } from '../../../themes/types';
import PersistentMessageContainer from './persistent-message-container';
import componentStyles from './persistent-message-wrap.style';
import { PersistentMessageStateType } from './types';

interface Props extends PersistentMessageStateType, IStylingProps {
  close?: () => any;
  show?: boolean;
}

interface State {
  height: number;
}

class PersistentMessageWrap extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { height: 0 };
  }

  onHeightReady = (height: number) => {
    this.setState({ height });
  };

  render() {
    const {
      show,
      theme,
      header,
      text,
      buttonSecondaryText,
      buttonPrimaryText,
      buttonSecondaryAction,
      buttonPrimaryAction,
      closeAction,
      close,
      ...restProps
    } = this.props;
    const { height } = this.state;

    const containerProps = { ...this.props };
    delete containerProps.show;

    const styles = componentStyles();

    return (
      <Box data-name="persistent-message" style={styles.wrapper({ show, height })} {...restProps}>
        <ReactHeight onHeightReady={this.onHeightReady}>
          <PersistentMessageContainer {...containerProps} />
        </ReactHeight>
      </Box>
    );
  }
}

export default PersistentMessageWrap;
