import React, { Component, ReactNode } from 'react';
import { UnmountClosed } from 'react-collapse';
import { IStylingProps } from '../../../themes/types';
import Box from '../../fela/Box';
import AccordionHead from './accordion-head';

export interface IProps extends IStylingProps {
  id?: string;
  children?: ReactNode;
  onClick?: Function | null;
  text?: string | ReactNode;
  itemAmount?: string | number | null;
  headCustom?: ReactNode;
  disabled?: boolean;
  hideArrow?: boolean;
  openStatic?: boolean;
  leaveBorder?: boolean;
  noSidePaddings?: boolean;
  noRoundBorder?: boolean;
  noBgColor?: boolean;
  containerHeight?: number;
  noTopBorder?: boolean;
  opened?: boolean;
  horizontalMargins?: boolean;
  noFixedHeight?: boolean;
  arrowStartDown?: boolean;
  onToggle?: (isOpened: boolean) => void;
}

interface IState {
  isOpened: boolean;
  prevOpened: boolean;
}

class Accordion extends Component<IProps, IState> {
  state = {
    isOpened: this.props.opened || false,
    prevOpened: this.props.opened || false,
  };

  static getDerivedStateFromProps(props: IProps, state: IState) {
    if (props.opened !== state.prevOpened) {
      return {
        isOpened: props.opened || false,
        prevOpened: props.opened,
      };
    }

    return null;
  }

  toggle = (): void => {
    this.setState({ isOpened: !this.state.isOpened });

    this.props.onToggle?.(!this.state.isOpened);
  };

  render() {
    const {
      id,
      children,
      onClick,
      text,
      headCustom,
      itemAmount,
      disabled,
      hideArrow,
      openStatic,
      leaveBorder,
      noSidePaddings,
      noRoundBorder,
      noBgColor,
      containerHeight,
      noTopBorder,
      horizontalMargins,
      noFixedHeight,
      arrowStartDown,
      ...restProps
    } = this.props;

    const wrapperProps = { ...restProps };
    delete wrapperProps.opened;

    return (
      <Box {...wrapperProps}>
        <AccordionHead
          onClick={onClick || this.toggle}
          id={id}
          text={text}
          headCustom={headCustom}
          itemAmount={itemAmount}
          disabled={disabled}
          leaveBorder={leaveBorder}
          noSidePaddings={noSidePaddings}
          noTopBorder={noTopBorder}
          noRoundBorder={noRoundBorder}
          noBgColor={noBgColor}
          containerHeight={containerHeight}
          openStatic={openStatic}
          hideArrow={hideArrow || openStatic}
          opened={openStatic || this.state.isOpened}
          horizontalMargins={horizontalMargins}
          noFixedHeight={noFixedHeight}
          arrowStartDown={arrowStartDown}
        />
        {openStatic ? (
          <Box>{children}</Box>
        ) : children ? (
          <UnmountClosed isOpened={this.state.isOpened}>{children}</UnmountClosed>
        ) : null}
      </Box>
    );
  }
}

export default Accordion;
